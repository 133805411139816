import styled from "styled-components";
import { colors } from "../../components/common/element/elements";

export const Styles = styled.div`
    .admission-page {
        .process-area {
            padding: 70px 0;
            
            .section-title {
                text-align: center;
                margin-bottom: 45px;
                
                h1 {
                    color: ${colors.black1};
                    font-weight: 600;
                    margin-bottom: 15px;
                    
                    @media(max-width: 767px) {
                        font-size: 26px;
                    }
                }

                p {
                    color: ${colors.text3};
                    font-size: 16px;
                    line-height: 1.5;
                    max-width: 600px;
                    margin: 0 auto;
                }
            }

            .process-section {
                margin-bottom: 40px;
                background: #ffffff;
                border-radius: 5px;
                box-shadow: 0 10px 18px rgba(0, 0, 0, 0.07);
                transition: all 0.3s ease;
                
                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
                }
                
                .section-header {
                    background: ${colors.bg1};
                    padding: 18px 25px;
                    border-radius: 5px 5px 0 0;
                    display: flex;
                    align-items: center;
                    
                    h3 {
                        color: #ffffff;
                        font-weight: 600;
                        font-size: 20px;
                        margin: 0;
                        
                        @media(max-width: 767px) {
                            font-size: 18px;
                        }
                    }
                }

                .process-list {
                    padding: 30px 25px;
                    
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        
                        li {
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 20px;
                            padding-bottom: 20px;
                            border-bottom: 1px solid ${colors.border1};
                            
                            .step-number {
                                width: 35px;
                                height: 35px;
                                background: ${colors.gr_bg};
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #ffffff;
                                font-weight: 600;
                                margin-right: 18px;
                                flex-shrink: 0;
                                font-size: 16px;
                            }
                            
                            .step-content {
                                color: ${colors.text2};
                                font-size: 15px;
                                line-height: 1.6;
                                padding-top: 5px;
                            }
                            
                            &:last-child {
                                margin-bottom: 0;
                                padding-bottom: 0;
                                border-bottom: none;
                            }
                            
                            @media(max-width: 767px) {
                                .step-number {
                                    width: 30px;
                                    height: 30px;
                                    font-size: 14px;
                                }
                                
                                .step-content {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            .admission-note {
                background: #f0f9ff;
                padding: 25px;
                border-radius: 5px;
                margin-top: 40px;
                border-left: 4px solid ${colors.blue};
                
                h4 {
                    color: ${colors.black2};
                    font-weight: 600;
                    margin-bottom: 12px;
                    font-size: 18px;
                }
                
                p {
                    color: ${colors.text3};
                    font-size: 15px;
                    line-height: 1.6;
                    margin: 0;
                }
            }

            @media(max-width: 767px) {
                padding: 40px 0;
            }
        }
    }
`;