import React, { Component } from "react";
import Datas from "../../data/facility-image/smart.json";

import { Container, Row, Col } from "react-bootstrap";

import { Styles } from "./styles/events.js";

import PopularSmart from "../../components/facilities/PopularSmart.js";
import HeaderTwoDefault from "../../components/default/HeaderTwoDefault.js";
import { BreadcrumbBoxDefault } from "../../components/common/default/BreadcrumbDefault.js";
import FooterTwoDefault from "../../components/default/FooterTwoDefault.js";

class SmartClass extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcrumb */}
          <BreadcrumbBoxDefault title="Smart Class" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Empowering Learning with Smart Classrooms</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-chalkboard-teacher"></i>{" "}
                          Interactive Learning
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-desktop"></i> Digitalized
                          Classrooms
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-graduation-cap"></i> Enhanced
                          Teaching Methods
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/digital1.jpg`
                        }
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Smart Class Overview</h5>
                      <p>
                        {" "}
                        Our <strong>smart classrooms</strong> provide an
                        immersive and interactive learning environment,
                        integrating advanced digital tools to make education
                        more engaging and effective. Equipped with cutting-edge
                        technology, these classrooms allow teachers to deliver
                        lessons dynamically, enhancing student comprehension and
                        retention. The incorporation of multimedia elements such
                        as videos, animations, and virtual simulations enables
                        students to grasp complex concepts with ease, making
                        learning more enjoyable and impactful.{" "}
                      </p>
                      <p>
                        {" "}
                        The integration of smart classrooms from an early stage
                        helps students become adept at using technology for
                        learning. From primary levels, students are introduced
                        to <strong>fundamental digital literacy</strong>, and as
                        they advance, they engage with more sophisticated tools,
                        such as{" "}
                        <strong>
                          educational software, coding platforms, and
                          interactive simulations
                        </strong>
                        . By the time they reach high school, they are
                        well-equipped to leverage technology in advanced
                        subjects, research projects, and real-world
                        applications.{" "}
                      </p>
                      ### **Key Features of Our Smart Classrooms**
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            <strong>
                              Modern Digital Boards for an Immersive Learning
                              Experience:
                            </strong>
                            Each classroom is equipped with state-of-the-art{" "}
                            <strong>interactive digital boards</strong> that
                            transform traditional lessons into engaging
                            multimedia presentations. 
                          </span>
                        </li>

                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            <strong>
                              Multimedia Content for Enhanced Conceptual
                              Learning:
                            </strong>
                            Lessons are enriched with a variety of{" "}
                            <strong>multimedia elements</strong> such as 3D
                            models, virtual simulations, and audio-visual
                            presentations to make learning more engaging.
                           
                          </span>
                        </li>

                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            <strong>
                              Collaborative and Interactive Learning:
                            </strong>
                            Smart classrooms promote{" "}
                            <strong>active student participation</strong>{" "}
                            through group discussions, virtual quizzes, and
                            interactive assignments.
                          </span>
                        </li>

                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            <strong>
                              Early Introduction to Technology and IT Education:
                            </strong>
                            Our curriculum incorporates{" "}
                            <strong>digital literacy from LKG onwards</strong>,
                            ensuring that students develop fundamental computing
                            skills from an early age. 
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="event-details-speaker">
                      <h5>Smart Class</h5>
                      <Row>
                        {Datas.map((data, i) => (
                          <Col lg="3" md="6" sm="6" key={i}>
                            <div className="event-speaker-item">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.personImage}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    {/* <div className="event-details-overview">
                      <h5>Library Committee</h5>
                      <p>
                        Our library is a knowledge hub with an extensive
                        collection of books, research materials, and digital
                        resources. It provides an ideal environment for students
                        to study, explore ideas, and enhance learning across
                        diverse subjects like literature, history, science, and
                        technology.Equipped with modern facilities, including
                        high-speed internet, digital archives, and multimedia
                        resources, students can access a wealth of information.
                      </p>
                      <div>
                        <ul className="list-unstyled">
                          <li>
                            <i className="fa">1</i>
                            <span className="font-weight-bold">
                              {" "}
                              Dr. Arun Kumar{" "}
                            </span>
                            <br />
                            <span>Chairman</span>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li>
                            <i className="fa">2</i>
                            <span> Dr. Priya Sharma </span>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li>
                            <i className="fa">3</i>
                            <span> Mr. Rajesh Verma </span>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li>
                            <i className="fa">4</i>
                            <span> Ms. Anjali Nair </span>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12">
                        <div className="event-sidebar-info">
                        <ul className="list-unstyled event-info-list">
                            <li>
                              Digital Boards: <span>All Classrooms</span>
                            </li>
                            <li>
                              Labs: <span>2 Spacious IT Labs</span>
                            </li>
                            <li>
                              Learning: <span>Interactive & Engaging</span>
                            </li>
                            <li>
                              Access: <span>From LKG Onwards</span>
                            </li>
                            <li>
                              Content: <span>Multimedia & Smart Tools</span>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="12">
                        <PopularSmart />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default SmartClass;
