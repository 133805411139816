import React, { Component } from "react";

import { BreadcrumbBoxDefault } from '../../../components/common/default/BreadcrumbDefault.js';
import { Styles } from "../styles/about.js";
import HeaderTwoDefault from '../../../components/default/HeaderTwoDefault.js';
import AboutUsDefault from '../../../components/default/AboutUsDefault.js';
import IconBoxDefault from '../../../components/default/IconBoxDefault.js';
import TabBoxDefault from '../../../components/default/TabBoxDefault.js';
import TestimonialSliderDefault from '../../../components/default/TestimonialSliderDefault.js';
import FooterTwoDefault from '../../../components/default/FooterTwoDefault.js';
import FaqEventDefault from '../../../components/default/FaqEventDefault.js';

class About extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper about-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcroumb */}
          <BreadcrumbBoxDefault title="About Us" />

          {/* About Area */}
          <AboutUsDefault />

          {/* Icon Box Area */}
          <IconBoxDefault />

          {/* Tab Section */}
          <TabBoxDefault />

          {/* Testimonial Slider */}
          <TestimonialSliderDefault />

                    {/* Faq & Event Area */}
                    <FaqEventDefault />

          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default About;