import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "../styles/tabBox.js";

class TabBoxMission extends Component {
  render() {
    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container>
            <Tab.Container defaultActiveKey="why">
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="why">
                        <i className="las la-arrow-right"></i> Why Amalambika
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      {/* <Nav.Link eventKey="mission">
                        <i className="las la-arrow-right"></i> Our Mission
                      </Nav.Link> */}
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="vision">
                        <i className="las la-arrow-right"></i> Our Vision
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      {/* <Nav.Link eventKey="ranking">
                        <i className="las la-arrow-right"></i> Our Ranking
                      </Nav.Link> */}
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="research">
                        <i className="las la-arrow-right"></i> Our Motto
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="why">
                      <h4 className="tab-title">Why Amalambika</h4>
                      <p className="tab-desc">
                        Amalambika Convent English School stands as a beacon of
                        excellence, offering a holistic education that nurtures
                        intellectual, moral, and social development. With a
                        strong foundation in values and academic rigor, the
                        school fosters a learning environment where students are
                        encouraged to strive for excellence in all aspects of
                        life. Rooted in the principles of wisdom, love, and
                        service, Amalambika ensures that every student receives
                        the best education while growing into responsible and
                        compassionate individuals.
                      </p>
                      <ul className="list-unstyled check-list">
    <li>
        <i className="fa fa-check"></i>Affiliated with the prestigious CISCE board, ensuring high academic standards and global recognition, providing students with a competitive edge in higher education and future careers.
    </li>
    <li>
        <i className="fa fa-check"></i>Comprehensive curriculum offering science, commerce, and computer science streams, enabling students to specialize while receiving a balanced education in core subjects.
    </li>
    <li>
        <i className="fa fa-check"></i>Strong focus on moral and spiritual values, cultivating ethical, compassionate, and socially responsible individuals committed to positive societal impact.
    </li>
</ul>

                    </Tab.Pane>
                    <Tab.Pane eventKey="mission">
                    <h4 className="tab-title">Our Mission</h4>
                    <p className="tab-desc">
  Our mission is to provide quality education, especially to the marginalized, while instilling strong moral and spiritual values. We aim to develop the skills, talents, and self-esteem of our students, preparing them to excel in both academics and life. With a focus on social justice and individual growth, we ensure that each student is equipped to contribute meaningfully to society. Amalambika School is affiliated with CISCE, guaranteeing a globally recognized education that opens doors to opportunities worldwide.
</p>
<ul className="list-unstyled check-list">
  <li>
    <i className="fa fa-check"></i>Affiliated with the prestigious CISCE board, providing globally recognized education and a path to international academic opportunities.
  </li>
  <li>
    <i className="fa fa-check"></i>Offers a comprehensive curriculum, including ISC streams in Science, Commerce, Computer Science, and Bio-maths, catering to diverse student interests and academic goals.
  </li>
  <li>
    <i className="fa fa-check"></i>Committed to the holistic development of students, focusing on academic excellence, moral values, skill development, and social justice.
  </li>
</ul>



                    </Tab.Pane>
                    <Tab.Pane eventKey="vision">
                    <h4 className="tab-title">Our Vision</h4>
<p className="tab-desc">
  Our vision is a young generation coming out of the institution imbibed with faith in God, equipped with most modern knowledge and possessing the best interpersonal relationship and in duties towards the society at large.
</p>
<ul className="list-unstyled check-list">
  <li>
    <i className="fa fa-check"></i>Generation imbibed with faith in God, equipped with modern knowledge.
  </li>
  <li>
    <i className="fa fa-check"></i>Students possessing the best interpersonal relationships.
  </li>
  <li>
    <i className="fa fa-check"></i>Commitment towards fulfilling duties to society at large.
  </li>
</ul>

                    </Tab.Pane>
                    <Tab.Pane eventKey="ranking">
                      <h4 className="tab-title">Our Ranking</h4>
                      <p className="tab-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum amet quo eius saepe et quis
                        necessitatibus hic natus facere a nisi fuga rem quas
                        molestias, eveniet minima molestiae. Lorem ipsum dolor,
                        sit amet consectetur adipisicing elit. Ea, recusandae?
                        Assumenda, error. Quam dicta iusto saepe. Odit minus
                        voluptas, fuga ipsum quia debitis totam, tempore
                        laudantium quasi dicta dolorem deleniti.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="research">
                    <h4 className="tab-title">Our Motto</h4>
<p className="tab-desc">
  WISDOM, LOVE, SERVICE
</p>
<ul className="list-unstyled check-list">
  <li>
    <i className="fa fa-check"></i>Wisdom: Striving for intellectual growth and sound decision-making that empowers individuals for a successful life.
  </li>
  <li>
    <i className="fa fa-check"></i>Love: Fostering a compassionate environment that builds trust, unity, and respect among all members of the community.
  </li>
  <li>
    <i className="fa fa-check"></i>Service: Encouraging a sense of responsibility and selflessness, motivating students to contribute positively to society and support those in need.
  </li>
</ul>



                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default TabBoxMission;
