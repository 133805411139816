import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
// import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo.js';

import About from './pages/about/default/About.js';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import BlogClassic from './pages/blog/BlogClassic';
import BlogGrid from './pages/blog/BlogGrid';
import BlogDetails from './pages/blog/BlogDetails';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';
import Rules from './pages/rulesandregulation/Rules.js';
import MissionDefault from './components/default/MissionDefault.js';
import HistoryDefault from './components/default/HistoryDefault.js';
import InfrastructureDefault from './pages/infrastructure/InfrastructureDeafult.js';
import Admission from './admission/Admission.js';
import UniformDefault from './uniform/UniformDefault.js';
import FacilityDetails from './pages/facilities/FacilityDetails.js';
import FacilityGrid from './pages/facilities/FacilityGrid.js';
import DigitalDetails from './pages/facilities/FacilityDetails.js';
import LabDetails from './pages/facilities/LabDetails.js';
import LibraryDetails from './pages/facilities/LibraryDetails.js';
import Policy from './pages/policies/Policy.js';
import Leadership from './pages/leadership/Leadership.js';
import Academics from './pages/academics/Academics.js';
import Fee from './pages/fees/Fee.js';
import Ground from './pages/facilities2/Ground.js';
import Library from './pages/facilities2/Library.js';
import Auditorium from './pages/facilities2/Auditorium.js';
import Lab from './pages/facilities2/Lab.js';
import Transportation from './pages/facilities2/Transportation.js';
import SmartClass from './pages/facilities2/SmartClass.js';
import Curriculum from './pages/curriculum/Curriculum.js';
import Awards from './pages/awards/Awards.js';
import GalleryDefault from './pages/galleryDefault/styles/GalleryDefault.js';
import CourseDefault from './pages/courseDefault/CourseDefault.js';
import ContactDefault from './pages/contactdefault/ContactDefault.js';




// import HomeTwoDefault from './components/default/HeaderTwoDefault.js';

function App() {
    return (
        <Router>
            <GlobalStyle />
            <ScrollToTop />
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={HomeTwo} />
                {/* <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo} /> */}
                <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />
                <Route path={`${process.env.PUBLIC_URL + "/course-grid"}`} component={CourseGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/course-list"}`} component={CourseList} />
                <Route path={`${process.env.PUBLIC_URL + "/course-details"}`} component={CourseDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/mission"}`} component={MissionDefault} />
                <Route path={`${process.env.PUBLIC_URL + "/instructor"}`} component={Instructor} />
                <Route path={`${process.env.PUBLIC_URL + "/instructor-details"}`} component={InstructorDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} component={Gallery} />
                <Route path={`${process.env.PUBLIC_URL + "/events"}`} component={Events} />
                <Route path={`${process.env.PUBLIC_URL + "/rules"}`} component={Rules} />
                <Route path={`${process.env.PUBLIC_URL + "/event-details"}`} component={EventDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/login"}`} component={Login} />
                <Route path={`${process.env.PUBLIC_URL + "/registration"}`} component={Register} />
                {/* <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact} /> */}
                <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} />
              
                <Route path={`${process.env.PUBLIC_URL + "/coming-soon"}`} component={ComingSoon} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component={BlogClassic} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component={BlogGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-details"}`} component={BlogDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/products"}`} component={Product} />
                <Route path={`${process.env.PUBLIC_URL + "/product-details"}`} component={ProductDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/cart"}`} component={Cart} />
                <Route path={`${process.env.PUBLIC_URL + "/school-infrastructure"}`} component={InfrastructureDefault} />
                <Route path={`${process.env.PUBLIC_URL + "/school-history"}`} component={HistoryDefault} />
                <Route path={`${process.env.PUBLIC_URL + "/admission"}`} component={Admission} />
                <Route path={`${process.env.PUBLIC_URL + "/school-uniform"}`} component={UniformDefault} />
                <Route path={`${process.env.PUBLIC_URL + "/facility-details"}`} component={FacilityDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/facility-grid"}`} component={FacilityGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/digital-details"}`} component={DigitalDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/lab-details"}`} component={LabDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/library-details"}`} component={LibraryDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/school-policy"}`} component={Policy} />
                <Route path={`${process.env.PUBLIC_URL + "/leadership"}`} component={Leadership} />
                <Route path={`${process.env.PUBLIC_URL + "/academics"}`} component={Academics} />
                <Route path={`${process.env.PUBLIC_URL + "/school-fee"}`} component={Fee} />
                <Route path={`${process.env.PUBLIC_URL + "/school-ground"}`} component={Ground} />
                <Route path={`${process.env.PUBLIC_URL + "/school-library"}`} component={Library} />
                <Route path={`${process.env.PUBLIC_URL + "/school-auditorium"}`} component={Auditorium}/>
                <Route path={`${process.env.PUBLIC_URL + "/school-lab"}`} component={Lab}/>
                <Route path={`${process.env.PUBLIC_URL + "/school-transportation"}`} component={Transportation}/>
                <Route path={`${process.env.PUBLIC_URL + "/school-smartclass"}`} component={SmartClass}/>
                <Route path={`${process.env.PUBLIC_URL + "/curriculum/:id"}`} component={Curriculum}/>
                <Route path={`${process.env.PUBLIC_URL + "/awards"}`} component={Awards}/>
                <Route path={`${process.env.PUBLIC_URL + "/school-gallery"}`} component={GalleryDefault}/>
                <Route path={`${process.env.PUBLIC_URL + "/curiculum-course"}`} component={CourseDefault}/>
                <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={ContactDefault}/>
                <Route path={`${process.env.PUBLIC_URL + "*"}`} component={PageNotFound} />


            </Switch>
        </Router>
    )
}

export default App;