import React from 'react';
import HeaderTwoDefault from '../../components/default/HeaderTwoDefault';
import { Styles } from './styles/rules';
import FooterTwoDefault from '../../components/default/FooterTwoDefault';
import { BreadcrumbBoxDefault } from '../../components/common/default/BreadcrumbDefault';

const Rules = () => {
    const rules = [
        {
            id: 1,
            title: "School Timing",
            rules: [
                "School starts at 8:00 AM sharp",
                "Students must arrive 10 minutes before school starts",
                "Late comers must obtain a gate pass from the office"
            ]
        },
        {
            id: 2,
            title: "Uniform",
            rules: [
                "Complete school uniform is mandatory",
                "School ID card must be worn at all times",
                "Sports uniform only allowed during PE classes"
            ]
        },
        {
            id: 3,
            title: "Attendance",
            rules: [
                "Minimum 90% attendance is required",
                "Leave application must be submitted in advance",
                "Medical certificate is required for sick leave"
            ]
        },
        {
            id: 4,
            title: "Discipline",
            rules: [
                "Respect teachers and fellow students",
                "No mobile phones during school hours",
                "Maintain cleanliness in school premises"
            ]
        },
        {
            id: 5,
            title: "Academic",
            rules: [
                "Complete all assignments on time",
                "Maintain class notes for all subjects",
                "Participate in class activities"
            ]
        }
    ];

 

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper instructor-details-page">

                {/* Header 2 */}
                <HeaderTwoDefault />

                {/* Breadcrumb */}
                <BreadcrumbBoxDefault title="Rules and Regulations" />

                {/* Instructor Details Area */}
                <section className="instructor-details-area">
                    <Styles>
                        <div className="school-rules-page">
                            <div className="rules-area">
                                <div className="container">
                                    <div className="section-title">
                                        <h1>School Rules and Regulations</h1>
                                    </div>

                                    {rules.map((section) => (
                                        <div key={section.id} className="rules-section">
                                            <div className="section-header">
                                                <h3>{section.title}</h3>
                                            </div>
                                            <div className="rules-list">
                                                <ul>
                                                    {section.rules.map((rule, index) => (
                                                        <li key={index}>
                                                            <div className="rule-number">{index + 1}</div>
                                                            <div className="rule-content">{rule}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="rules-note">
                                        <h4>Important Note:</h4>
                                        <p>Violation of these rules will lead to disciplinary action as per school policy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Styles>
                </section>

                {/* Footer 2 */}
                <FooterTwoDefault />

            </div>
        </Styles>
    );
};

export default Rules;
