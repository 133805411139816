import React, { Component } from 'react';
// import HeaderTwo from './components/HeaderTwo';
import HeaderTwoDefault from './components/default/HeaderTwoDefault';
// import HeroImage from './components/HeroImage';
// import ServiceBox from './components/ServiceBox';
// import AboutUsTwo from './components/AboutUsTwo';
// import CourseSlider from './components/CourseSlider';
// import NumberCounter from './components/NumberCounter';
// import FreeCourse from './components/FreeCourse';
// import TeamSlider from './components/TeamSlider';
// import TestimonialSlider from './components/TestimonialSlider';
// import HomeBlog from './components/HomeBlog';
// import ImageGallery from './components/ImageGallery';
// import FooterTwo from './components/FooterTwo';
import HeroImageDefault from './components/default/HeroImageDefault';
import ServiceBoxDefault from './components/default/ServiceBoxDefault';
import AboutUsTwoDefault from './components/default/AboutUsTwoDefault';
import CourseSliderDefault from './components/default/CourseSliderDefault';
import NumberCounterDefault from './components/default/NumberCounterDefault';
import TeamSliderDefault from './components/default/TeamSliderDefault';
import HomeBlogDefault from './components/default/HomeBlogDefault';
import ImageGalleryDefault from './components/default/ImageGalleryDeafult';
import FooterTwoDefault from './components/default/FooterTwoDefault';
import TestimonialSliderDefault from './components/default/TestimonialSliderDefault';



export default class HomeTwo extends Component {
    render() {
        return (
            <div className="main-wrapper">

                {/* Header 2 */}
                <HeaderTwoDefault />

                {/* Hero Image */}
                <HeroImageDefault />

                {/* Service Box */}
                <ServiceBoxDefault />

                {/* About Us 2 */}
                <AboutUsTwoDefault />

                {/* Course Slider */}
                <CourseSliderDefault />

                {/* Counter Area */}
                <NumberCounterDefault />

                {/* Free Course Area */}
                {/* <FreeCourse /> */}

                {/* Team Slider */}
                <TeamSliderDefault />

                {/* Testimonial Slider */}
                <TestimonialSliderDefault />

                {/* Blog Area */}
                <HomeBlogDefault/>

                {/* Image Gallery Area */}
                <ImageGalleryDefault />

                {/* Footer 2 */}
                <FooterTwoDefault />

            </div>
        )
    }
}
