const feesData = [
    { id: 1, name: "Registration Fee", amount: 2500.00 },
    { id: 2, name: "Admission Fee", amount: 2500.00 },
    { id: 3, name: "Establishment Fee", amount: 500.00 },
    { id: 4, name: "Development Fee", amount: 500.00 },
    { id: 5, name: "Tuition Fee (Nur.)", amount: 700.00 },
    { id: 6, name: "Tuition Fee (LKG, UKG)", amount: 700.00 },
    { id: 7, name: "Tuition Fee (I to V)", amount: 800.00 },
    { id: 8, name: "Tuition Fee (VI & VII)", amount: 900.00 },
    { id: 9, name: "Tuition Fee (VIII)", amount: 1200.00 }
  ];
  
  export default feesData;
  