import React, { Component } from 'react';
import { Styles } from '../../courses/styles/courseCategory';

class InfrastructureCategorys extends Component {
    render() {
        return (
            <Styles>
                {/* Infrastructure Category */}
                <div className="course-category">
                    <h5>Infrastructure Category</h5>
                    <ul className="category-item list-unstyled">
                        <li className="check-btn">
                            <label htmlFor="cat1">Digital Classrooms</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat2">Science Laboratories</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat3">Library Resources</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat4">Sports Facilities</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat5">Activity Areas</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat6">Residential Facilities</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat7">Security Systems</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="cat8">Other Facilities</label>
                        </li>
                    </ul>
                </div>
            </Styles>
        )
    }
}

export default InfrastructureCategorys;