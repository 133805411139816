import React, { Component } from 'react';
import { Styles } from '../../courses/styles/coursePrice';

class FacilityLevel extends Component {
    render() {
        return (
            <Styles>
                {/* Facility Level */}
                <div className="course-price">
                    <h5>Facility Level</h5>
                    <ul className="price-item list-unstyled">
                        <li className="check-btn">
                            <label htmlFor="price1">Primary Level</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="price2">Secondary Level</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="price3">Higher Secondary</label>
                        </li>
                        <li className="check-btn">
                            <label htmlFor="price4">Common Facilities</label>
                        </li>
                    </ul>
                </div>
            </Styles>
        )
    }
}

export default FacilityLevel;