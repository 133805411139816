import React, { Component } from "react";
import Datas from "../../data/facility-image/lab.json";

import { Container, Row, Col } from "react-bootstrap";

import { Styles } from "./styles/events.js";

import PopularLab from "../../components/facilities/PopularLab.js";
import HeaderTwoDefault from "../../components/default/HeaderTwoDefault.js";
import { BreadcrumbBoxDefault } from "../../components/common/default/BreadcrumbDefault.js";
import FooterTwoDefault from "../../components/default/FooterTwoDefault.js";

class Lab extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcrumb */}
          <BreadcrumbBoxDefault title="Science Lab" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>State-of-the-Art Science & Computer Labs</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-desktop"></i> Advanced Computer
                          Lab
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-atom"></i> Well-Equipped Science
                          Labs
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-microscope"></i> Hands-on
                          Learning Experience
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={process.env.PUBLIC_URL + `/assets/images/lab2.jpg`}
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Science Lab Overview</h5>
                      <p>
                        Our school features a{" "}
                        <strong>well-furnished computer lab</strong> that serves
                        as a hub for technological learning and innovation.
                        Equipped with state-of-the-art infrastructure, the lab
                        provides students with hands-on experience in computing,
                        programming, and digital skills. It fosters a dynamic
                        learning environment where students are encouraged to
                        explore technology, develop problem-solving abilities,
                        and enhance their creativity through various digital
                        projects. The lab is designed to support{" "}
                        <strong>modern learning methodologies</strong>, ensuring
                        that students gain essential technological competencies
                        necessary for academic and professional success.
                      </p>
                      <p>
                        The computer lab is structured to offer a{" "}
                        <strong>flexible and interactive space</strong> where
                        students can work on diverse tasks, including{" "}
                        <strong>
                          creating websites, designing graphics, editing
                          documents, preparing presentations, and coding
                          software applications
                        </strong>
                        . Additionally, students use the facility to{" "}
                        <strong>
                          complete assignments, collaborate on research
                          projects, communicate via email, analyze data, and
                          access a vast range of digital library resources
                        </strong>
                        . This well-integrated digital learning approach
                        enhances their ability to adapt to the evolving
                        technological landscape.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            <strong>
                              Two spacious, high-tech computer labs:
                            </strong>{" "}
                            Our institution provides two well-equipped computer
                            labs with modern hardware and high-speed internet
                            connectivity.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            <strong>
                              Early exposure to information technology from LKG:
                            </strong>{" "}
                            We introduce students to fundamental computing
                            concepts from a young age, starting in Lower
                            Kindergarten (LKG).
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            <strong>
                              Advanced IT syllabi for high school and higher
                              secondary students:
                            </strong>{" "}
                            Our curriculum includes an extensive and progressive
                            IT syllabus for senior students.
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="event-details-speaker">
                      <h5>Lab Facilities</h5>
                      <Row>
                        {Datas.map((data, i) => (
                          <Col lg="3" md="6" sm="6" key={i}>
                            <div className="event-speaker-item">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.personImage}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    {/* <div className="event-details-overview"> */}
                    {/* <h5>Library Committee</h5> */}
                    {/* <p>
                        Our library is a knowledge hub with an extensive
                        collection of books, research materials, and digital
                        resources. It provides an ideal environment for students
                        to study, explore ideas, and enhance learning across
                        diverse subjects like literature, history, science, and
                        technology.Equipped with modern facilities, including
                        high-speed internet, digital archives, and multimedia
                        resources, students can access a wealth of information.
                      </p> */}
                    {/* <div>
      <ul className="list-unstyled">
        <li>
          <i className="fa">1</i>
          <span className="font-weight-bold"> Dr. Arun Kumar </span>
          <br />
          <span>Chairman</span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">2</i>
          <span> Dr. Priya Sharma </span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">3</i>
          <span> Mr. Rajesh Verma </span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">4</i>
          <span> Ms. Anjali Nair </span>
        </li>
      </ul>
    </div> */}

                    {/* </div> */}
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12">
                        <div className="event-sidebar-info">
                          <ul className="list-unstyled event-info-list">
                            <li>
                              Computers: <span>Latest tech</span>
                            </li>
                            <li>
                              Labs: <span>Physics, Chemistry, Biology</span>
                            </li>
                            <li>
                              Tech: <span>Smart tools</span>
                            </li>
                            <li>
                              Access: <span>All grades</span>
                            </li>
                            <li>
                              Practice: <span>Hands-on</span>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="12">
                        <PopularLab />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default Lab;
