import React, { Component } from "react";


import { Container, Row, Col } from "react-bootstrap";

import { Styles } from "./styles/events.js";

import PopularLibrary from "../../components/facilities/PopularLibrary.js";
import HeaderTwoDefault from "../../components/default/HeaderTwoDefault.js";
import { BreadcrumbBoxDefault } from "../../components/common/default/BreadcrumbDefault.js";
import FooterTwoDefault from "../../components/default/FooterTwoDefault.js";

class Library extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcrumb */}
          <BreadcrumbBoxDefault title="Library" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Comprehensive Library for Academic Excellence</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-book"></i> Extensive Book
                          Collection
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-desktop"></i> Digital Library
                          Access
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-user-graduate"></i> Research &
                          Study Areas
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/library1.jpg`
                        }
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Library Overview</h5>

                      <p>
                        Our library is a <strong>spacious knowledge hub</strong>{" "}
                        designed to inspire and support learning. With a vast
                        collection of books, it caters to a wide range of
                        subjects, including literature, science, history,
                        technology, and languages. Whether students seek
                        academic references, engaging fiction, or
                        thought-provoking non-fiction, they will find a diverse
                        selection to suit their interests. 
                        In addition to our{" "}
                        <strong>
                          extensive collection of periodicals and weekly
                          publications,
                        </strong>{" "}
                        the library provides access to the latest academic
                        journals, research papers, and industry magazines. This
                        ensures that students stay updated with current affairs,
                        scientific advancements, and literary trends, fostering
                        a habit of continuous learning and intellectual
                        curiosity.
                        <br />
                   
                      </p>

                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Our library houses a vast collection of over{" "}
                            <strong>15,000 books</strong>, covering a wide range
                            of subjects, including literature, science, history,
                            and technology.
                          </span>
                        </li>

                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            In addition to books, the library provides access to
                            numerous{" "}
                            <strong>periodicals and weekly publications</strong>
                            , ensuring that students and faculty stay informed
                            about the latest developments in various fields.
                          </span>
                        </li>

                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            The library is designed to encourage learning beyond
                            the classroom through{" "}
                            <strong>dedicated reading hours</strong>. During
                            these hours, students are encouraged to immerse
                            themselves in books of their choice and explore new
                            ideas.
                          </span>
                        </li>

                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A well-organized and peaceful reading environment is
                            maintained to support focused study and research.
                            The structured seating arrangements, noise-free
                            ambiance, and ample lighting create an ideal space
                            for students.
                          </span>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="event-details-speaker">
                      <h5>Library Facilities</h5>
                      <Row>
                        {Datas.map((data, i) => (
                          <Col lg="3" md="6" sm="6" key={i}>
                            <div className="event-speaker-item">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.personImage}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div> */}
                    {/* <div className="event-details-overview">
                      <h5>Library Committee</h5> */}
                      {/* <p>
                        Our library is a knowledge hub with an extensive
                        collection of books, research materials, and digital
                        resources. It provides an ideal environment for students
                        to study, explore ideas, and enhance learning across
                        diverse subjects like literature, history, science, and
                        technology.Equipped with modern facilities, including
                        high-speed internet, digital archives, and multimedia
                        resources, students can access a wealth of information.
                      </p> */}
                      {/* <div>
                        <ul className="list-unstyled">
                          <li>
                            <i className="fa">1</i>
                            <span className="font-weight-bold">
                              {" "}
                              Dr. Arun Kumar{" "}
                            </span>
                            <br />
                            <span>Chairman</span>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li>
                            <i className="fa">2</i>
                            <span> Dr. Priya Sharma </span>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li>
                            <i className="fa">3</i>
                            <span> Mr. Rajesh Verma </span>
                          </li>
                        </ul>

                        <ul className="list-unstyled">
                          <li>
                            <i className="fa">4</i>
                            <span> Ms. Anjali Nair </span>
                          </li>
                        </ul>
                      </div> */}
                    {/* </div> */}
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12">
                        <div className="event-sidebar-info">
                          <ul className="list-unstyled event-info-list">
                            <li>
                              Collection: <span>15k+ Books</span>
                            </li>
                            <li>
                            Reading Hours:  <span>Dedicated Slots</span>
                            </li>
                            <li>
                             Genres: <span>Fiction,Classics</span>
                            </li>
                            <li>
                              Membership: <span>For All Students</span>
                            </li>
                            <li>
                              Resources: <span>Research Materials</span>
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="12">
                        <PopularLibrary />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default Library;
