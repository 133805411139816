import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

// Define colors (you can adjust these values)


export const Styles = styled.div`
    .school-rules-page {
        .rules-area {
            padding: 70px 0;
            
            .section-title {
                text-align: center;
                margin-bottom: 45px;
                
                h1 {
                    color: ${colors.black1};
                    font-weight: 600;
                    margin-bottom: 20px;
                    
                    @media(max-width: 767px) {
                        font-size: 26px;
                    }
                }
            }

            .rules-section {
                margin-bottom: 40px;
                background: #ffffff;
                border-radius: 5px;
                box-shadow: 0 10px 18px rgba(0, 0, 0, 0.07);
                
                .section-header {
                    background: ${colors.bg1};
                    padding: 15px 25px;
                    border-radius: 5px 5px 0 0;
                    
                    h3 {
                        color: #ffffff;
                        font-weight: 600;
                        font-size: 20px;
                        margin: 0;
                        
                        @media(max-width: 767px) {
                            font-size: 18px;
                        }
                    }
                }

                .rules-list {
                    padding: 25px;
                    
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        
                        li {
                            display: flex;
                            align-items: center;
                            margin-bottom: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid ${colors.border1};
                            
                            .rule-number {
                                width: 30px;
                                height: 30px;
                                background: ${colors.green};
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #ffffff;
                                font-weight: 600;
                                margin-right: 15px;
                                flex-shrink: 0;
                            }
                            
                            .rule-content {
                                color: ${colors.text2};
                                font-size: 15px;
                                line-height: 25px;
                            }
                            
                            &:last-child {
                                margin-bottom: 0;
                                padding-bottom: 0;
                                border-bottom: none;
                            }
                            
                            @media(max-width: 767px) {
                                .rule-number {
                                    width: 25px;
                                    height: 25px;
                                    font-size: 12px;
                                }
                                
                                .rule-content {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            .rules-note {
                background: #fff7e3;
                padding: 20px;
                border-radius: 5px;
                margin-top: 30px;
                
                h4 {
                    color: ${colors.black2};
                    font-weight: 600;
                    margin-bottom: 10px;
                    font-size: 18px;
                }
                
                p {
                    color: ${colors.text3};
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0;
                }
            }

            @media(max-width: 767px) {
                padding: 40px 0;
            }
        }
    }
`;