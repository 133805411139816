import React, { Component } from "react";
import Datas from "../../data/facility-image/ground.json";

import { Container, Row, Col } from "react-bootstrap";


import { Styles } from "./styles/events.js";





import PopularGround from "../../components/facilities/PopularGround.js";
import HeaderTwoDefault from "../../components/default/HeaderTwoDefault.js";
import { BreadcrumbBoxDefault } from "../../components/common/default/BreadcrumbDefault.js";
import FooterTwoDefault from "../../components/default/FooterTwoDefault.js";

class Ground extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcrumb */}
          <BreadcrumbBoxDefault title="Playground" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                    <h4>Experience Outdoor Activities at Our Spacious Playground</h4>
                    </div>
                    <div className="event-icon">
                    <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-futbol"></i> Football Ground
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-basketball-ball"></i> Basketball Court
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-running"></i> Running Track
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/ground2.jpg`
                        }
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Playground Overview</h5>
                      <p>  
   Our playground is a vibrant hub for students to engage in sports, recreation, and physical activities. Spanning a large, well-maintained area, it provides excellent facilities for football, cricket, basketball, and other outdoor games. With clearly marked courts and fields, students can enjoy their favorite sports in a structured and safe environment, fostering teamwork, discipline, and competitive spirit.  
</p>  

<p>  
   In addition to the sports fields, the playground features a dedicated running track designed for fitness enthusiasts who enjoy jogging, sprinting, or endurance training. The track is ideal for students looking to improve their stamina and maintain a healthy lifestyle. Ample open space allows for additional recreational activities such as yoga, stretching, and warm-up exercises.The playground is not just a place for physical activity but also a space where students can unwind and refresh their minds  
</p>  


<ul className="list-unstyled">  
  <li>  
    <i className="fa fa-check"></i>  
    <span>  
   Our playground includes a vast, well-maintained football and cricket ground, offering students the perfect environment to develop their athletic skills. 
    </span>  
  </li>  

  <li>  
    <i className="fa fa-check"></i>  
    <span>   
      To cater to diverse sporting interests, the playground is equipped with dedicated courts for basketball, volleyball, and badminton.  
    </span>  
  </li>  

  <li>  
    <i className="fa fa-check"></i>  
    <span>   
      For those focused on physical fitness, our playground includes a dedicated jogging and running track. The track is designed with a smooth surface to ensure a comfortable and safe running experience.  
    </span>  
  </li>  

  <li>  
    <i className="fa fa-check"></i>  
    <span>  
       
      Beyond sports and fitness, our playground is designed to be an inviting space for spectators, faculty, and students who wish to relax. 
    </span>  
  </li>  
</ul>
                    </div>
                
                    <div className="event-details-speaker">
                      <h5>Playground</h5>
                      <Row>
                        {Datas.map((data, i) => (
                          <Col lg="3" md="6" sm="6" key={i}>
                            <div className="event-speaker-item">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.personImage}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    {/* <div className="event-details-overview">
                      <h5>Library Committee</h5> */}
                      {/* <p>
                        Our library is a knowledge hub with an extensive
                        collection of books, research materials, and digital
                        resources. It provides an ideal environment for students
                        to study, explore ideas, and enhance learning across
                        diverse subjects like literature, history, science, and
                        technology.Equipped with modern facilities, including
                        high-speed internet, digital archives, and multimedia
                        resources, students can access a wealth of information.
                      </p> */}
 {/* <div>
      <ul className="list-unstyled">
        <li>
          <i className="fa">1</i>
          <span className="font-weight-bold"> Dr. Arun Kumar </span>
          <br />
          <span>Chairman</span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">2</i>
          <span> Dr. Priya Sharma </span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">3</i>
          <span> Mr. Rajesh Verma </span>
        </li>
      </ul>

      <ul className="list-unstyled">
        <li>
          <i className="fa">4</i>
          <span> Ms. Anjali Nair </span>
        </li>
      </ul>
    </div> */}


                    </div>
                  {/* </div> */}

            
                </Col>

                

                <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12">
                        <div className="event-sidebar-info">
                        <ul className="list-unstyled event-info-list">
                            <li>Size: <span>5 Acres</span></li>
                            <li>Facilities: <span>Football, Cricket</span></li>
                            <li>Running Track: <span>400m Track</span></li>
                            <li>Seating Capacity: <span>500+ Spectators</span></li>
                            <li>Access: <span>Open for All Students</span></li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="12">
                        <PopularGround/>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default Ground;
