import React, { Component } from 'react';
import Datas from '../../data/instructor/details.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';  // Importing PDF icon
import { Styles } from './styles/policy';
import FooterTwoDefault from '../../components/default/FooterTwoDefault';
import { BreadcrumbBoxDefault } from '../../components/common/default/BreadcrumbDefault';
import HeaderTwoDefault from '../../components/default/HeaderTwoDefault';

class Policy extends Component {
    render() {
        const settings = {
            slidesPerView: 3,
            loop: false,
            speed: 1000,
            autoplay: false,
            spaceBetween: 30,
            watchSlidesVisibility: false,
            breakpoints: {
                0: { slidesPerView: 1 },
                576: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                992: { slidesPerView: 3 }
            }
        };

        return (
            <Styles>
                <div className="main-wrapper instructor-details-page">
                    <HeaderTwoDefault />
                    <BreadcrumbBoxDefault title="Policy" />

                    <section className="instructor-details-area">
                        <Container>
                            <Row>
                                <Col md="12">
                                    <div className="instructor-course-title">
                                        <h5>Policies & Documents</h5>
                                    </div>
                                    <div className="instructor-course-slider">
                                        <Swiper {...settings}>
                                            {Datas.map((data, i) => (
                                                <div className="course-item" key={i}>
                                                    <Link to={process.env.PUBLIC_URL + data.courseLink}>
                                                        <div className="pdf-container d-flex align-items-center ">
                                                            {/* <FaFilePdf size={30} color="red" /> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"/><path d="M14 2v4a2 2 0 0 0 2 2h4"/><path d="M10 9H8"/><path d="M16 13H8"/><path d="M16 17H8"/></svg>
                                                            <p className="ml-2 ">
                                                                {data.courseTitle} - Click to view
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </Swiper>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <FooterTwoDefault />
                </div>
            </Styles>
        );
    }
}

export default Policy;
