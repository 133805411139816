import React, { useEffect } from 'react';
import Datas from '../../data/footer/footer2-default.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from '../common/BackToTop';
import { Styles } from "../styles/footerTwo.js";

function FooterTwoDefault() {
    useEffect(() => {
        const form = document.getElementById("form4");
        const email = document.getElementById("email4");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const emailValue = email.value.trim();

            if (emailValue === "") {
                setError(email, "Email can't be blank");
            } else if (!isEmail(emailValue)) {
                setError(email, "Not a valid email");
            } else {
                setSuccess(email);
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".input-msg4");
            formControl.className = "form-control error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }
    });

    return (
        <Styles>
            {/* Footer Two */}
            <footer className="footer2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})` }}>
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="footer-logo-info">
                                <img src={process.env.PUBLIC_URL + "/assets/images/footerlogo2.png"} alt="" className="img-fluid" />
                                <p>Amalambika Convent English School in Thekkady, founded in 1977, fosters excellence in education for all communities.</p>
                                <ul className="list-unstyled">
                                    <li><i className="las la-map-marker"></i>Thekkady P.O Idukki Dist, 685536</li>
                                    <li><i className="las la-envelope"></i>info@amalambika.com</li>
                                    <li><i className="las la-phone"></i> 04869 223 422</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="3">
    <div className="f-links">
        <h5>Quick Links</h5>
        <ul className="list-unstyled">
            <li><Link to={process.env.PUBLIC_URL + "/about"}><i className="las la-angle-right"></i>About</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Admission</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Academics</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Facilities</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/school-gallery"}><i className="las la-angle-right"></i>Photo Gallery</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Event</Link></li>
        </ul>
    </div>
</Col>

<Col md="3">
    <div className="f-post">
        <h5>Connect With Us</h5>
        <div className="post-box d-flex">
            <div className="po-icon">
                <i className="fab fa-facebook"></i>
            </div>
            <div className="po-content">
            <a href="https://www.facebook.com/amalambika.school" target="_blank" rel="noopener noreferrer">Facebook</a>

            </div>
        </div>
        <div className="post-box d-flex">
            <div className="po-icon">
                <i className="fab fa-instagram"></i>
            </div>
            <div className="po-content">
                <a href="https://www.instagram.com/_acest_/" target="_blank" rel="noopener noreferrer">Instagram</a>
            </div>
        </div>
        <div className="post-box d-flex">
            <div className="po-icon">
                <i className="fab fa-youtube"></i>
            </div>
            <div className="po-content">
                <a href="https://www.youtube.com/@amalambikathekkady1190" target="_blank" rel="noopener noreferrer">YouTube</a>
            </div>
        </div>
    </div>
</Col>
<Col md="3">
   <div className="f-newsletter">
       <h5>Newsletter</h5>
       <p>Stay updated with our latest news, events and achievements!</p>

       <form id="form4" className="form">
           <p className="form-control">
               <input type="email" placeholder="Enter your email address" id="email4" />
               <span className="input-msg4"></span>
           </p>
           <button>Subscribe Now</button>
       </form>
   </div>
</Col>
                     
                        {/* <Col md="12">
                            <div className="copytext-area text-center">
                                <p>Copyright &copy; 2017 | Designed With <i className="las la-heart"></i> by <a href={process.env.PUBLIC_URL + "/"} target="_blank" rel="noopener noreferrer">SnazzyTheme</a></p>
                                <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                </ul>
                            </div>
                        </Col> */}
                    </Row>
                </Container>

                {/* Back To Top  */}
                <BackToTop />
            </footer>
        </Styles>
    );
}

export default FooterTwoDefault
