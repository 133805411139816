export const navLinks = [
    {
      id: "home",
      name: "Home",
      href: "/",
    },
    {
      id: "about",
      name: "About School",
      href: "/",
      subMenu: [
        { id: "school", name: "The School", href: "/about" },
        { id: "mission", name: "Mission & Vision", href: "/mission" },
        { id: "history", name: "History", href: "/" },
        { id: "infrastructure", name: "Infrastructure", href: "/school-infrastructure" },
        { id: "policies", name: "Policies", href: "/" },
        { id: "leadership", name: "School Management Committee", href: "/" },
        { id: "awards", name: "Awards and Recognition", href: "/" },
      ],
    },
    {
      id: "admission",
      name: "Admission",
      href: "#",
      subMenu: [
     
        { id: "admission", name: "Admission", href: "/" },
        { id: "uniform", name: "School Uniform", href: "/" },
   
      ]
    },
    {
      id: "academics",
      name: "Academics",
      href: "#",
      subMenu: [
        { id: "academic", name: "Academics", href: "/" },
        { id: "curriculum", name: "Curiculum & Course", href: "/" },
        { id: "fee", name: "Fee Structure", href: "/" },
        { id: "rules", name: "Rules & Regulations", href: "/" },
      ]
    },
    {
      id: "facilities",
      name: "Facilities",
      href: "#",
      subMenu: [
        { id: "ground", name: "Ground", href: "/school-ground" },
        { id: "library", name: "Library", href: "/school-library" },
        { id: "auditorium", name: "Auditorium", href: "/" },
        { id: "lab", name: "Lab", href: "/school-lab" },
        { id: "transportation", name: "Transportation", href: "/" },
        { id: "smartclass", name: "Smart Class", href: "/school-smartclass" },
      ]
    },

    {
      id: "gallery",
      name: "Gallery",
      href: "/school-gallery",
    },
    {
      id: "curriculum",
      name: "Others",
      href: "#",
      subMenu: [
        { id: "clubs", name: "Clubs", href: "/" },
        { id: "sports ", name: "Sports & Activities", href: "/curriculum/sports" },
        { id: "ncc", name: "NCC", href: "/" },
        { id: "nss", name: "NSS", href: "/" },
        { id: "drawing", name: "Drawing Class", href: "/curriculum/drawing" },
      ]
    },
  ];
  