import React, { Component } from "react";
import Datas from "../../data/event/details.json";

import { Container, Row, Col } from "react-bootstrap";


import { Styles } from "./styles/eventDetails.js";
import HeaderTwoDefault from "../../components/default/HeaderTwoDefault.js";
import { BreadcrumbBoxDefault } from "../../components/common/default/BreadcrumbDefault.js";
import FooterTwoDefault from "../../components/default/FooterTwoDefault.js";
import PopularLibrary from "./components/PopularLibrary.js";

class LibraryDetails extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcrumb */}
          <BreadcrumbBoxDefault title="Library" />

          {/* Library Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Comprehensive Library for Academic Excellence</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-book"></i> Extensive Book
                          Collection
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-desktop"></i> Digital Library
                          Access
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-user-graduate"></i> Research &
                          Study Areas
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/librarydetail.jpg`
                        }
                        alt="Library"
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Library Overview</h5>
                      <p>
                        Our library is a knowledge hub with an extensive
                        collection of books, research materials, and digital
                        resources. It provides an ideal environment for students
                        to study, explore ideas, and enhance learning across
                        diverse subjects like literature, history, science, and
                        technology.Equipped with modern facilities, including
                        high-speed internet, digital archives, and multimedia
                        resources, students can access a wealth of information.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            A diverse collection of books covering various
                            subjects, spanning across multiple
                          </span>
                          <br />
                          <span>
                            disciplines and areas of knowledge for all
                            interests.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Access to a wide range of online journals, e-books,
                            and research papers available
                          </span>
                          <br />
                          <span>
                            through the library's digital resources for
                            comprehensive learning.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Dedicated quiet zones are available for students and
                            researchers who prefer a
                          </span>
                          <br />
                          <span>
                            peaceful environment for focused study and critical
                            thinking.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>
                            Multiple computer stations provide access to digital
                            resources, research tools, and
                          </span>
                          <br />
                          <span>
                            the internet for academic and personal use.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-speaker">
                      <h5>Library Facilities</h5>
                      <Row>
                        {Datas.map((data, i) => (
                          <Col lg="3" md="6" sm="6" key={i}>
                            <div className="event-speaker-item">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.personImage}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12">
                        <div className="event-sidebar-info">
                          <ul className="list-unstyled event-info-list">
                          <li>Collection: <span>50k+ Books</span></li>
          <li>Digital Access: <span>E-books & Journals</span></li>
          <li>Study Areas: <span>Private & Group</span></li>
          <li>Technology: <span>Catalog</span></li>
          <li>Membership: <span>For All Students</span></li>
          <li>Resources: <span>Research Materials</span></li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="12">
                        <PopularLibrary />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default LibraryDetails;
