import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col} from 'react-bootstrap';

// import StickyMenu from '../common/StickyMenu';
// import MobileMenu from '../common/MobileMenu';
import { Styles } from "../styles/headerTwo.js";
import MobileMenuDefault from '../common/default/MobileMenuDefault.js';
import StickyMenuDefault from '../common/default/StickyMenuDefault.js';
import {navLinks} from "../../constant/layout.js"

class HeaderTwoDefault extends Component {
    render() {
        return (
            <Styles>
                {/* Topbar 2 */}
                <section className="top-bar2">
                    <Container>
                        <Row>
                            <Col lg="7" md="9">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><i className="las la-phone"></i>04869 223 422</li>
                                        <li className="list-inline-item"><i className="las la-envelope"></i>info@amalambika.com</li>
                                        <li className="list-inline-item"><i className="las la-map-marker"></i>Thekkady P.O Idukki 
                                    </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="5" md="3">
                                <div className="bar-right d-flex justify-content-end">
                                    {/* <ul className="list-unstyled list-inline bar-lang">
                                        <li className="list-inline-item">
                                            <Dropdown>
                                                <Dropdown.Toggle><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" />English<i className="las la-angle-down"></i></Dropdown.Toggle>
                                                <Dropdown.Menu as="ul">
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="" /> English</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/fra.png"} alt="" /> French</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/ger.png"} alt="" /> German</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/spa.png"} alt="" /> Spanish</Dropdown.Item>
                                                    <Dropdown.Item as="li"><img src={process.env.PUBLIC_URL + "/assets/images/bra.png"} alt="" /> Brazilian</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul> */}
                                    <ul className="list-unstyled list-inline bar-social">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "https://www.facebook.com/amalambika.school"}><i className="fab fa-facebook-f"></i></a></li>
                                        {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li> */}
                                        {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li> */}
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "https://www.youtube.com/@amalambikathekkady1190"}><i className="fab fa-youtube"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "https://www.instagram.com/_acest_/"}><i className="fab fa-instagram"></i></a></li>
                                    </ul>

                                    {/* <ul className="list-unstyled list-inline sidebar-button">
                                        <li className="list-inline-item nav-item side-box">
                                            <Sidebar />
                                        </li>
                                    </ul> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area 2 */}
                <section className="logo-area2">
                    <Container>
                        <Row>
                            <Col md="2">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/amalambika-logo.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="10">
                                <div className="menu-box d-flex justify-content-end">
                                <ul className="nav menu-nav">
                      {navLinks?.map((item) => (
                        <li
                          key={item?.name}
                          className="nav-item dropdown active"
                        >
                          <Link
                            className="nav-link dropdown-toggle"
                            to={`${item?.href}`}
                            data-toggle="dropdown"
                          >
                            {item?.name}
                            {item?.subMenu && (
                              <i className="las la-angle-down"></i>
                              )}
                          </Link>
                          {item?.subMenu && (
                            <ul className="dropdown list-unstyled">
                              {item?.subMenu?.map((subItem) => (
                                <li
                                  className="nav-item active"
                                  key={subItem?.name}
                                >
                                  <Link className="nav-link" to={`${process.env.PUBLIC_URL}${subItem?.href}`}>
                                    {subItem?.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                                    {/* <div className="search-box">
                                        <Search />
                                    </div> */}
                                    <div className="apply-btn">
                                        <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
                <StickyMenuDefault navLinks={navLinks}/>

                {/* Mobile Menu */}
                <MobileMenuDefault navLinks={navLinks} />
            </Styles>
        )
    }
}

export default HeaderTwoDefault;
