import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from '../courses/styles/course.js';
import InfrastructureSidebar from './components/InfrastructureSidebar.js';
import InfrastructureItemList from './components/InfrastructureItems.js';
import HeaderTwoDefault from '../../components/default/HeaderTwoDefault.js';
import { BreadcrumbBoxDefault } from '../../components/common/default/BreadcrumbDefault.js';
import FooterTwoDefault from '../../components/default/FooterTwoDefault.js';

class InfrastructureDefault extends Component {

    render() {
        return (
            <div className="main-wrapper course-page">

                {/* Header 2 */}
                <HeaderTwoDefault />

                {/* Breadcroumb */}
                <BreadcrumbBoxDefault title="Infrastructure" />

                <Styles>
                    {/* Course Grid */}
                    <section className="course-list-area">
                        <Container>
                            <Row>
                                <Col lg="3" md="4" sm="5">
                                    <InfrastructureSidebar />
                                </Col>
                                <Col lg="9" md="8" sm="7">
                                    <div className="course-items2">
                                        <Row>
                                            <InfrastructureItemList />
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Styles>

                {/* Footer 2 */}
                <FooterTwoDefault />

            </div>
        )
    }
}

export default InfrastructureDefault