import React, { Component } from "react";
import Datas from "../../data/event/details.json";

import { Container, Row, Col } from "react-bootstrap";

import PopularCourse from "./../facilities/components/PopularCourse.js";

import { Styles } from "./styles/eventDetails.js";
import HeaderTwoDefault from "../../components/default/HeaderTwoDefault.js";
import { BreadcrumbBoxDefault } from "../../components/common/default/BreadcrumbDefault.js";
import FooterTwoDefault from "../../components/default/FooterTwoDefault.js";

class DigitalDetails extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcroumb */}
          <BreadcrumbBoxDefault title="Digital Classrooms" />

          {/* Event Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>
                        Digital Learning Environment for Enhanced Education
                      </h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-desktop"></i> Smart Classrooms
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-laptop"></i> Computer Labs
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-wifi"></i> Digital Resources
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/digitaldetails.jpg`
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Digital Classroom Overview</h5>
                      <p>
                        Our school allows teachers and learners the freedom to
                        explore the limitless possibilities of technologies
                        which will enhance their potential. All the classrooms
                        are digitalized to enable the students to understand the
                        topics easily. Smart class makes learning an enjoyable
                        experience.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i> All classrooms are
                          equipped with digital tools to make learning easier
                          and more interactive. The smart classrooms turn
                          learning into a fun and engaging experience for
                          students.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> The school features a
                          modern computer lab designed for new learning modules.
                          It provides students with a flexible space for
                          creating websites, completing assignments, and
                          accessing various digital resources.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Basics of information
                          technology are taught from LKG onwards. This early
                          introduction ensures that students gain foundational
                          tech skills from a young age.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Advanced syllabi are
                          available for both high school and higher secondary
                          students. These syllabi ensure students receive a
                          comprehensive and challenging education in technology.
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-speaker">
                      <h5>Digital Classroom</h5>
                      <Row>
                        {Datas.map((data, i) => (
                          <Col lg="3" md="6" sm="6" key={i}>
                            <div className="event-speaker-item">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.personImage}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                              {/* <div className="img-content text-center">
                                <h6>{data.personName}</h6> */}
                                {/* <ul className="list-unstyled list-inline">
                                  <li className="list-inline-item">
                                    <a
                                      href={
                                        process.env.PUBLIC_URL +
                                        data.socialLinks.facebook
                                      }
                                    >
                                      <i className="fab fa-facebook-f"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item">
                                    <a
                                      href={
                                        process.env.PUBLIC_URL +
                                        data.socialLinks.twitter
                                      }
                                    >
                                      <i className="fab fa-twitter"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item">
                                    <a
                                      href={
                                        process.env.PUBLIC_URL +
                                        data.socialLinks.youtube
                                      }
                                    >
                                      <i className="fab fa-youtube"></i>
                                    </a>
                                  </li>
                                </ul> */}
                              {/* </div> */}
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>

                    {/* <div className="pre-nxt-event">
                      <Row>
                        <Col md="6">
                          <div className="next-prev-item prev-event d-flex">
                            <div className="prev-img">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/gallery-03.jpg`
                                }
                                alt=""
                              />
                            </div>
                            <div className="prev-text">
                              <p>
                                <Link
                                  to={process.env.PUBLIC_URL + "/event-details"}
                                >
                                  Lorem, ipsum dolor sit amet consectetur
                                  adipisicing elit Perferendis.
                                </Link>
                              </p>
                              <span> March 27, 2020</span>
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="next-prev-item next-event d-flex flex-row-reverse text-right">
                            <div className="next-img">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/gallery-06.jpg`
                                }
                                alt=""
                              />
                            </div>
                            <div className="next-text">
                              <p>
                                <Link
                                  to={process.env.PUBLIC_URL + "/event-details"}
                                >
                                  Lorem, ipsum dolor sit amet consectetur
                                  adipisicing elit Perferendis.
                                </Link>
                              </p>
                              <span> March 27, 2020</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12">
                                <div className="event-details-sidebar">
                                    <Row>
                                        <Col md="12">
                                            <div className="event-sidebar-info">
                                                <ul className="list-unstyled event-info-list">
                                                    <li>Technology: <span>Smart Classes</span></li>
                                                    <li>Labs: <span>2 Computer Labs</span></li>
                                                    <li>Starting Level: <span>LKG Onwards</span></li>
                                                    <li>Advanced Programs: <span>High School</span></li>
                                                    <li>Features: <span>Website Development</span></li>
                                                    <li>Resources: <span>Digital Library</span></li>
                                                </ul>
                                                {/* <Link to="/contact">
                                                    <button type="button" className="buy-btn">Contact Us</button>
                                                </Link> */}
                                            </div>
                                        </Col>
                      <Col md="12">
                        <PopularCourse />
                      </Col>
                      {/* <Col md="12">
                        <CourseTag />
                      </Col> */}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default DigitalDetails;
