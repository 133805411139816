import React, { useEffect } from 'react';
import Datas from '../../data/faq-event/faq-event.json';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "../styles/faqEvent.js";

function FaqEventDefault() {
    useEffect(() => {
        const accordionButton = document.querySelectorAll(".accordion-button");
        accordionButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "accordion-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "accordion-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    }, []);

    return (
        <Styles>
            <section className="event-faq-area">
                <Container>
                    <Row>
                        {/* Single Column containing both FAQ sections */}
                        <Col md="12">
                            <div className="faq-area">
                                <div className="sec-title">
                                    <h4>Frequently Asked <span>Questions</span></h4>
                                </div>

                                <Row>
                                    {/* First FAQ Section */}
                                    <Col md="6">
                                        <div className="faq-box">
                                            {Datas.faqDataList.map((faqData, i) => (
                                                <div className="faq-item" key={i}>
                                                    <button className="accordion-button">
                                                        <div className="accordion-icon"><i className="las la-plus"></i></div>
                                                        <p>{faqData.faqTitle}</p>
                                                    </button>
                                                    <div className="accordion-content">
                                                        <p>{faqData.faqDesc}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>

                                    {/* Second FAQ Section (Same Class Names) */}
                                    <Col md="6">
                                        <div className="faq-box">
                                            {Datas.faqDataList2.map((faqData, i) => (
                                                <div className="faq-item" key={i}>
                                                    <button className="accordion-button">
                                                        <div className="accordion-icon"><i className="las la-plus"></i></div>
                                                        <p>{faqData.faqTitle}</p>
                                                    </button>
                                                    <div className="accordion-content">
                                                        <p>{faqData.faqDesc}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    );
}

export default FaqEventDefault;
