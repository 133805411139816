import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from './styles/awards';
import HeaderTwoDefault from '../../components/default/HeaderTwoDefault';
import FooterTwoDefault from '../../components/default/FooterTwoDefault';
import { BreadcrumbBoxDefault } from '../../components/common/default/BreadcrumbDefault';

class Awards extends Component {
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper event-details-page">

                    {/* Header 2 */}
                    <HeaderTwoDefault />

                    {/* Breadcroumb */}
                    <BreadcrumbBoxDefault title="Achievements & Awards" />

                    {/* Event Details Area */}
                    <section className="event-details-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <div className="event-details-content">
                                        {/* <div className="heading">
                                            <h4>International students coming in Uk from Asian subcontinant for better education.</h4>
                                        </div> */}
                                        {/* <div className="event-icon">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><i className="las la-calendar"></i> 19 February, 2021</li>
                                                <li className="list-inline-item"><i className="las la-clock"></i> 10:30am</li>
                                                <li className="list-inline-item"><i className="las la-map-marker"></i> 795 South Park Avenue, CA</li>
                                                <li className="list-inline-item"><i className="las la-copy"></i> Social Science</li>
                                            </ul>
                                        </div> */}
                                        {/* <div className="event-details-banner">
                                            <img src={process.env.PUBLIC_URL + `/assets/images/event-banner.jpg`} alt="" className="img-fluid" />
                                        </div> */}
                                        <div className="event-details-overview">
                                            <h5>International Awards</h5>
                                            <p>In 2023, SJCC initiative MV Cinemas produced and directed the anti-drug movie titled "Balloons", which won accolades at international film festivals, demonstrating its global impact. It bagged the Best Cause-Driven Film Award at the CANNES WORLD FILM FESTIVAL in France (2023), solidifying its global impact. Additionally, the film received the Outstanding Achievement Award at the Microfilm INDIE Short Fest in Los Angeles. These accolades demonstrate the effectiveness of "Balloons" in raising awareness about the devastating consequences of drug abuse.. </p>
                                            {/* <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                            </ul> */}
                                        </div>
                                        <div className="event-details-overview">
                                            <h5>International Awards</h5>
                                            <p>In 2023, SJCC initiative MV Cinemas produced and directed the anti-drug movie titled "Balloons", which won accolades at international film festivals, demonstrating its global impact. It bagged the Best Cause-Driven Film Award at the CANNES WORLD FILM FESTIVAL in France (2023), solidifying its global impact. Additionally, the film received the Outstanding Achievement Award at the Microfilm INDIE Short Fest in Los Angeles. These accolades demonstrate the effectiveness of "Balloons" in raising awareness about the devastating consequences of drug abuse.. </p>
                                            {/* <ul className="list-unstyled">
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                                <li><i className="fa fa-check"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere Quae impedit eligendi perspiciatis animi maxime ab minus corporis omnis similique excepturi.</li>
                                            </ul> */}
                                        </div>
                                        {/* <div className="event-details-speaker">
                                            <h5>Event Speakers</h5>
                                            <Row>
                                                {
                                                    Datas.map((data, i) => (
                                                        <Col lg="3" md="6" sm="6" key={i}>
                                                            <div className="event-speaker-item">
                                                                <img src={process.env.PUBLIC_URL + `/assets/images/${data.personImage}`} alt="" className="img-fluid" />
                                                                <div className="img-content text-center">
                                                                    <h6>{data.personName}</h6>
                                                                    <ul className="list-unstyled list-inline">
                                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.facebook}><i className="fab fa-facebook-f"></i></a></li>
                                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.twitter}><i className="fab fa-twitter"></i></a></li>
                                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.youtube}><i className="fab fa-youtube"></i></a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </div> */}

                                        {/* <div className="pre-nxt-event">
                                            <Row>
                                                <Col md="6">
                                                    <div className="next-prev-item prev-event d-flex">
                                                        <div className="prev-img">
                                                            <img src={process.env.PUBLIC_URL + `/assets/images/gallery-03.jpg`} alt="" />
                                                        </div>
                                                        <div className="prev-text">
                                                            <p><Link to={process.env.PUBLIC_URL + "/event-details"}>Lorem, ipsum dolor sit amet consectetur adipisicing elit Perferendis.</Link></p>
                                                            <span> March 27, 2020</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="next-prev-item next-event d-flex flex-row-reverse text-right">
                                                        <div className="next-img">
                                                            <img src={process.env.PUBLIC_URL + `/assets/images/gallery-06.jpg`} alt="" />
                                                        </div>
                                                        <div className="next-text">
                                                            <p><Link to={process.env.PUBLIC_URL + "/event-details"}>Lorem, ipsum dolor sit amet consectetur adipisicing elit Perferendis.</Link></p>
                                                            <span> March 27, 2020</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div> */}
                                    </div>
                                </Col>

                                {/* <Col lg="3" md="4" sm="12">
                                    <div className="event-details-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="event-sidebar-info"> */}
                                                    {/* <div className="event-sidebar-timer text-center">
                                                        <Timer initialTime={1040 * 970 * 980} direction="backward">
                                                            <p><Timer.Days /><span>Days</span></p>
                                                            <p><Timer.Hours /><span>Hours</span></p>
                                                            <p><Timer.Minutes /><span>Minutes</span></p>
                                                        </Timer>
                                                    </div> */}
                                                    {/* <ul className="list-unstyled event-info-list">
                                                        <li>Start Date: <span>Aug 21, 2020</span></li>
                                                        <li>Time: <span>08:30am</span></li>
                                                        <li>Seat: <span>220</span></li>
                                                        <li>Place: <span>Ontario,CA</span></li>
                                                        <li>Organizer: <span>David Young</span></li>
                                                        <li>Phone: <span>+1 (396) 486 4709</span></li>
                                                        <li>Email: <span>email@me.com</span></li>
                                                        <li>Website: <span>www.mydomain.com</span></li>
                                                    </ul>
                                                    <button type="button" className="buy-btn">Buy Ticket</button>
                                                </div>
                                            </Col> */}
                                            {/* <Col md="12">
                                                <PopularCourse />
                                            </Col>
                                            <Col md="12">
                                                <CourseTag />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> */}
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwoDefault />

                </div>
            </Styles>
        )
    }
}

export default Awards