import React, { Component } from 'react';
import Datas from '../../data/facility/grid.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { Styles } from './styles/blog'
import HeaderTwoDefault from '../../components/default/HeaderTwoDefault';
import { BreadcrumbBoxDefault } from '../../components/common/default/BreadcrumbDefault';
import FooterTwoDefault from '../../components/default/FooterTwoDefault';

class FacilityGrid extends Component {

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper blog-grid-page">

                    {/* Header 2 */}
                    <HeaderTwoDefault />

                    {/* Breadcroumb */}
                    <BreadcrumbBoxDefault title="Facilities" />

                    {/* Blog Classic */}
                    <section className="blog-grid-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <Row>
                                        {
                                            Datas.map((data, i) => (
                                                <Col lg="4" md="6" sm="6" key={i}>
                                                    <div className="blog-item">
                                                        <div className="blog-img">
                                                            <Link to={process.env.PUBLIC_URL + data.postLink}><img src={process.env.PUBLIC_URL + `/assets/images/${data.postImg}`} alt="" className="img-fluid" /></Link>
                                                        </div>
                                                        <div className="blog-content">
                                                            <div className="blog-auth_date d-flex">
                                                                {/* <div className="author-img d-flex">
                                                                    <Link to={process.env.PUBLIC_URL + data.authorLink}><img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" /></Link>
                                                                    <p><Link to={process.env.PUBLIC_URL + data.authorLink}>{data.authorName}</Link></p>
                                                                </div> */}
                                                                {/* <div className="post-date">
                                                                    <p><i className="las la-calendar"></i> {data.postDate}</p>
                                                                </div> */}
                                                            </div>
                                                            <div className="blog-title">
                                                                <h6><Link to={process.env.PUBLIC_URL + data.postLink}>{data.postTitle}</Link></h6>
                                                            </div>
                                                            <div className="blog-desc">
                                                                <p>{data.postDesc}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>

                                    {/* <div className="text-center">
                                        <Pagination />
                                    </div> */}
                                </Col>
                                {/* <Col lg="3" md="4" sm="5">
                                    <BlogSidebar />
                                </Col> */}
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwoDefault />

                </div>
            </Styles>
        )
    }
}

export default FacilityGrid