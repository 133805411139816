import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Styles } from "./styles/fee.js";
import feesData from "../../data/fee/feetable.js";
import HeaderTwoDefault from "../../components/default/HeaderTwoDefault.js";
import FooterTwoDefault from "../../components/default/FooterTwoDefault.js";
import { BreadcrumbBoxDefault } from "../../components/common/default/BreadcrumbDefault.js";

class Fee extends Component {
  render() {
 

    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper product-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcroumb */}
          <BreadcrumbBoxDefault title="Fee Structure" />

          {/* Product Details */}
          <section className="product-details-area">
            <Container>
              <Row>
                {/* <Col md="7">
                                    <div className="product-information">
                                        <div className="product-title">
                                            <h4>Xiaomy LED Light Bulb With Verious Model</h4>
                                        </div>
                                        <div className="product-rating d-flex">
                                            <ul className="rating list-unstyled list-inline">
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                            </ul>
                                            <div className="review-num">
                                                <Link to={process.env.PUBLIC_URL + "/"}>( 07 Review )</Link>
                                            </div>
                                        </div>
                                        <div className="product-price d-flex">
                                            <p className="dc-price">$139.00</p>
                                            <p className="ac-price">$199.00</p>
                                        </div>
                                        <div className="product-desc">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam et dolorem nostrum quos placeat. Quibusdam necessitatibus adipisci dignissimos autem voluptate, dicta ullam, assumenda qui, corporis ut vero neque sapiente possimus.</p>
                                        </div>
                                        <div className="product-stock">
                                            <p>Availability : <span className="stock">In Stock</span> <span className="stock-num">(09 Available)</span></p>
                                        </div>
                                        <div className="product-color">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item">Color :</li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="color-1" name="color" value="color-1" />
                                                    <label htmlFor="color-1"><span><i className="las la-check"></i></span></label>
                                                </li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="color-2" name="color" value="color-2" />
                                                    <label htmlFor="color-2"><span><i className="las la-check"></i></span></label>
                                                </li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="color-3" name="color" value="color-3" />
                                                    <label htmlFor="color-3"><span><i className="las la-check"></i></span></label>
                                                </li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="color-4" name="color" value="color-4" />
                                                    <label htmlFor="color-4"><span><i className="las la-check"></i></span></label>
                                                </li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="color-5" name="color" value="color-5" />
                                                    <label htmlFor="color-5"><span><i className="las la-check"></i></span></label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="product-size">
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item">Size :</li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="size-1" name="size" value="size-1" />
                                                    <label htmlFor="size-1">S</label>
                                                </li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="size-2" name="size" value="size-2" />
                                                    <label htmlFor="size-2">M</label>
                                                </li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="size-3" name="size" value="size-3" />
                                                    <label htmlFor="size-3">L</label>
                                                </li>
                                                <li className="list-inline-item">
                                                    <input type="radio" id="size-4" name="size" value="size-4" />
                                                    <label htmlFor="size-4">XL</label>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="product-cart-wh-com-btn">
                                            <Link to={process.env.PUBLIC_URL + "/product-details"} className="cart-btn">Add To Cart</Link>
                                            <Link to={process.env.PUBLIC_URL + "/product-details"} className="wishlist-btn"><i className="far fa-heart"></i></Link>
                                            <Link to={process.env.PUBLIC_URL + "/product-details"} className="compare-btn"><i className="fas fa-random"></i></Link>
                                        </div>
                                    </div>
                                </Col> */}

                <Col md="12">
                  <div className="product-tab">
                    <div className="additional-tab">
                      <Table className="table table-bordered">
                        <thead>
                            <tr >
                                <th colSpan={3} className="text-center">The Amount is payable at the time of admission is as below:</th>
                            </tr>
                          <tr>
                            <th>No</th>
                            <th>Description</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        {feesData.map((item) => (
                          <tbody>
                            <tr>
                              <td>{item?.id}</td>
                              <td>{item?.name}</td>
                              <td>{item?.amount}</td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwoDefault/>
        </div>
      </Styles>
    );
  }
}

export default Fee;
