import React, { Component } from "react";
import Datas from "../../data/facility/popular2.json";

import { Container, Row, Col } from "react-bootstrap";

import Popular2 from "./../facilities/components/Popular2.js";

import { Styles } from "./styles/eventDetails.js";
import HeaderTwoDefault from "../../components/default/HeaderTwoDefault.js";
import { BreadcrumbBoxDefault } from "../../components/common/default/BreadcrumbDefault.js";
import FooterTwoDefault from "../../components/default/FooterTwoDefault.js";

class LabDetails extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcroumb */}
          <BreadcrumbBoxDefault title="School Lab" />

          {/* Event Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>Innovative School Lab for Future-Ready Education</h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-laptop"></i> Modern Computer Labs
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-book-reader"></i> Science
                          Laboratories
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-chalkboard-teacher"></i> Smart
                          Classrooms
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/digitaldetails.jpg`
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>School Lab Overview</h5>
                      <p>
                        Our school labs are designed to foster creativity,
                        critical thinking, and innovation. With state-of-the-art
                        facilities, students engage in hands-on learning that
                        enhances their understanding of scientific principles.
                        These labs provide a dynamic space for exploring
                        physics, chemistry, and biology through real-world
                        experiments. Equipped with modern tools and safety
                        measures, they ensure students can conduct practical
                        work with confidence. Interactive sessions and guided
                        experiments help bridge the gap between theory and
                        practice. By working on innovative projects, students
                        develop problem-solving skills essential for future
                        scientific and technological advancements. Our goal is
                        to create an environment where curiosity thrives and
                        young minds are encouraged to push the boundaries of
                        knowledge.
                      </p>
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-check"></i> Our science labs are
                          well-equipped to prepare students for scientific
                          advancements. These facilities help them explore
                          concepts through hands-on experimentation.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> We have dedicated,
                          fully furnished labs for physics, chemistry, and
                          biology. Each lab is designed to provide students with
                          a safe and engaging learning environment.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> These labs provide
                          hands-on experience to reinforce classroom learning.
                          Practical applications of theories enhance students'
                          problem-solving and analytical skills.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Practical experiments
                          help students develop a deeper understanding of
                          scientific concepts. Engaging in real-world
                          applications fosters curiosity and innovation.
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-speaker">
                      <h5>Digital Classroom</h5>
                      <Row>
                        {Datas.map((data, i) => (
                          <Col lg="3" md="6" sm="6" key={i}>
                            <div className="event-speaker-item">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/${data.personImage}`
                                }
                                alt=""
                                className="img-fluid"
                              />
                              {/* <div className="img-content text-center">
                                <h6>{data.personName}</h6> */}
                              {/* <ul className="list-unstyled list-inline">
                                  <li className="list-inline-item">
                                    <a
                                      href={
                                        process.env.PUBLIC_URL +
                                        data.socialLinks.facebook
                                      }
                                    >
                                      <i className="fab fa-facebook-f"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item">
                                    <a
                                      href={
                                        process.env.PUBLIC_URL +
                                        data.socialLinks.twitter
                                      }
                                    >
                                      <i className="fab fa-twitter"></i>
                                    </a>
                                  </li>
                                  <li className="list-inline-item">
                                    <a
                                      href={
                                        process.env.PUBLIC_URL +
                                        data.socialLinks.youtube
                                      }
                                    >
                                      <i className="fab fa-youtube"></i>
                                    </a>
                                  </li>
                                </ul> */}
                              {/* </div> */}
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>

                    {/* <div className="pre-nxt-event">
                      <Row>
                        <Col md="6">
                          <div className="next-prev-item prev-event d-flex">
                            <div className="prev-img">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/gallery-03.jpg`
                                }
                                alt=""
                              />
                            </div>
                            <div className="prev-text">
                              <p>
                                <Link
                                  to={process.env.PUBLIC_URL + "/event-details"}
                                >
                                  Lorem, ipsum dolor sit amet consectetur
                                  adipisicing elit Perferendis.
                                </Link>
                              </p>
                              <span> March 27, 2020</span>
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="next-prev-item next-event d-flex flex-row-reverse text-right">
                            <div className="next-img">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/gallery-06.jpg`
                                }
                                alt=""
                              />
                            </div>
                            <div className="next-text">
                              <p>
                                <Link
                                  to={process.env.PUBLIC_URL + "/event-details"}
                                >
                                  Lorem, ipsum dolor sit amet consectetur
                                  adipisicing elit Perferendis.
                                </Link>
                              </p>
                              <span> March 27, 2020</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12">
                        <div className="event-sidebar-info">
                          <ul className="list-unstyled event-info-list">
                            <li>
                              Labs: <span>Physics, Chemistry, Biology</span>
                            </li>
                            <li>
                              Learning: <span>Hands-on Experiments</span>
                            </li>
                            <li>
                              Safety: <span>Fully Equipped</span>
                            </li>
                            <li>
                              Technology: <span>Modern Equipment</span>
                            </li>
                            <li>
                              Innovation: <span>Project-Based Learning</span>
                            </li>
                            <li>
                              Resources: <span>Advanced Lab Tools</span>
                            </li>
                        
                          </ul>
                          {/* <Link to="/contact">
                                                    <button type="button" className="buy-btn">Contact Us</button>
                                                </Link> */}
                        </div>
                      </Col>
                      <Col md="12">
                        <Popular2 />
                      </Col>
                      {/* <Col md="12">
                        <CourseTag />
                      </Col> */}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default LabDetails;
