import React, { Component } from 'react';
import Datas from '../../data/instructor/instructor.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import Pagination from './../../components/Pagination';
import { Styles } from './styles/leadership.js';
import HeaderTwoDefault from '../../components/default/HeaderTwoDefault.js';
import FooterTwoDefault from '../../components/default/FooterTwoDefault.js';
import { BreadcrumbBoxDefault } from '../../components/common/default/BreadcrumbDefault.js';

class Leadership extends Component {
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper instructor-page">

                    {/* Header 2 */}
                    <HeaderTwoDefault />

                    {/* Breadcroumb */}
                    <BreadcrumbBoxDefault title="Team" />

                    {/* Instructor Area */}
                    <Col md="12">
                                  
                                     <section className="instructor-area">
                        <Container>
                     
                            <Row>
                                {
                                   
                                    Datas.map((data, i) => (
                                        <Col lg="3" md="4" sm="6" key={i}>
                                            <div className="instructor-item">
                                                <Link to={process.env.PUBLIC_URL + "/instructor-details"}><img src={process.env.PUBLIC_URL + `/assets/images/${data.personImage}`} alt="" className="img-fluid" /></Link>
                                                <div className="img-content text-center">
                                                    <h5><Link to={process.env.PUBLIC_URL + "/instructor-details"}>{data.personName}</Link></h5>
                                                    <p>{data.personTitle}</p>
                                                    <ul className="list-unstyled list-inline">
                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.facebook}><i className="fab fa-facebook-f"></i></a></li>
                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.twitter}><i className="fab fa-twitter"></i></a></li>
                                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + data.socialLinks.youtube}><i className="fab fa-youtube"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }

                                <Col md="12" className="text-center">
                                    <Pagination />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                                </Col>

                    {/* Footer 2 */}
                    <FooterTwoDefault />

                </div>
            </Styles>
        )
    }
}

export default  Leadership 