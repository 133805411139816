import React, { Component } from 'react';
import Datas from '../../../data/gallery/gallery-page.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";




import { Styles } from './styles/gallery.js';
import HeaderTwoDefault from '../../../components/default/HeaderTwoDefault.js';
import FooterTwoDefault from '../../../components/default/FooterTwoDefault.js';
import { BreadcrumbBoxDefault } from '../../../components/common/default/BreadcrumbDefault.js';

class GalleryDefault extends Component {
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper gallery-page">

                    {/* Header 2 */}
                    <HeaderTwoDefault />

                    {/* Breadcroumb */}
                    <BreadcrumbBoxDefault title="Gallery" />

                    {/* Gallery Area */}
                    <section className="gallery-page-area">
                        <Container>
                            <Row>
                                {
                                    Datas.map((data, i) => (
                                        <Col lg="4" sm="6" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage small={process.env.PUBLIC_URL + `/assets/images/${data.galleryImage}`} large={process.env.PUBLIC_URL + `/assets/images/${data.galleryImage}`} alt="" />
                                            </div>
                                        </Col>
                                    ))
                                }

                                {/* <Col md="12" className="text-center">
                                    <Pagination />
                                </Col> */}
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwoDefault />

                </div>
            </Styles>
        )
    }
}

export default GalleryDefault