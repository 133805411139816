import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "../styles/missiondefault";
import HeaderTwoDefault from "./HeaderTwoDefault";
import { BreadcrumbBoxDefault } from "../common/default/BreadcrumbDefault";
import FooterTwoDefault from "./FooterTwoDefault";

import TestimonialSliderDefault from "./TestimonialSliderDefault";
import FaqEventDefault from "./FaqEventDefault";
import TabBoxMission from "./TabBoxMission";

class MissionDefault extends Component {
  render() {
    
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper instructor-details-page">
          {/* Header 2 */}
          <HeaderTwoDefault />

          {/* Breadcroumb */}
          <BreadcrumbBoxDefault title="Mission & Vision" />

          {/* Instructor Details Area */}
          <section className="instructor-details-area">
            <Container>
              <Row>
                <Col md="4">
                  <div className="instructor-img">
                    <img
                      src={process.env.PUBLIC_URL + `/assets/images/missions.jpg`}
                      alt=""
                      className="img-fluid"
                    />
                    {/* <ul className="list-unstyled getintouch">
                      <li>
                        <i className="las la-phone"></i> +1 (396) 486 4709
                      </li>
                      <li>
                        <i className="lar la-envelope"></i> mail@contactme.com
                      </li>
                      <li>
                        <i className="lab la-skype"></i> contact.me
                      </li>
                    </ul> */}
                    {/* <ul className="social list-unstyled list-inline">
                      <li className="list-inline-item">
                        <a href={process.env.PUBLIC_URL + "/"}>
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href={process.env.PUBLIC_URL + "/"}>
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href={process.env.PUBLIC_URL + "/"}>
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href={process.env.PUBLIC_URL + "/"}>
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href={process.env.PUBLIC_URL + "/"}>
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </Col>
                <Col md="8">
                  <div className="instructor-content">
                    <h4>Our Mission</h4>
                    <span>Providing Quality Education for All</span>
                    <p>
                      Our mission is to provide quality education to all
                      especially the marginalised, to serve as a venue for
                      social justice to instill a firm commitment to spiritual
                      and moral value, develop skill, talents and self-esteem
                      and give response to the special need of individual
                      students.
                      
                      <div className="mt-2">
                      Amalambika School is affiliated to the prestigious Council
                      for the Indian Certificate of Secondary Education, New
                      Delhi, India’s most preferred Education Board.
                      </div>
                      <div className="mt-2">
                      The School follows a course of studies prescribed by the
                      council for the Indian Certificate of Secondary Education
                      known as CISCE. We also have Plus Two classes with ISC
                      syllabus, which is a continuation of the ICSE. There are
                      three streams of studies- Science, Commerce, Computer
                      Science and Bio-maths. 
                    </div>
                      <div className="mt-2">
                      At Amalambika School, we go beyond academics to nurture a well-rounded educational experience. Our curriculum is designed to integrate modern teaching methodologies with a strong foundation in ethics and values. With a dedicated team of educators, state-of-the-art infrastructure, and a student-centric approach, we aim to create an environment where learning is engaging and transformative.
                    </div>
                    </p>
                  </div>
               
                  {/* <div className="qual-expe d-flex">
                    <div className="qualification">
                      <h5>Qualifications</h5>
                      <div className="qual-expe-box">
                        <h6>University of California</h6>
                        <p>Bachelor of Computer Science & Engeniering</p>
                      </div>
                      <div className="qual-expe-box">
                        <h6>University of California</h6>
                        <p>Masters of Computer Science & Engeniering</p>
                      </div>
                    </div>
                    <div className="experiance">
                      <h5>Experiance</h5>
                      <div className="qual-expe-box">
                        <h6>SnazzyTheme.com</h6>
                        <p>2016 - 2019</p>
                      </div>
                      <div className="qual-expe-box">
                        <h6>Envato Market</h6>
                        <p>2019 - Present</p>
                      </div>
                    </div>

                  </div> */}
                    
                </Col>
              
               
              </Row>
            
            </Container>
        

            <TabBoxMission/>
             <TestimonialSliderDefault/>
             <FaqEventDefault/>
          </section>



          {/* Footer 2 */}
          <FooterTwoDefault />
        </div>
      </Styles>
    );
  }
}

export default MissionDefault;
