import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { Styles } from './styles/club.js';
import HeaderTwoDefault from '../../components/default/HeaderTwoDefault.js';
import { BreadcrumbBoxDefault } from '../../components/common/default/BreadcrumbDefault.js';
import FooterTwoDefault from '../../components/default/FooterTwoDefault.js';

// Content Mapping
const clubContent = {
    clubs: { heading:"Clubs",title: "Clubs", description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos itaque atque ullam iste temporibus, laudantium reprehenderit doloremque hic aliquam repellat modi et quam nemo eos alias, maxime nobis necessitatibus optio!orem ipsum dolor sit amet, consectetur adipisicing elit. Quos itaque atque ullam iste temporibus, laudantium reprehenderit doloremque hic aliquam repellat modi et quam nemo eos alias, maxime nobis necessitatibus optio!",title2: "Lion Clubs", description2: "l" },
    sports: { heading:"Sports & Activities",title: " Athletics ", description: "Athletics plays a vital role in shaping students' physical and mental strength. Our school provides specialized training in various track and field events, helping students develop discipline, endurance, and competitive spirit. The year 2023-24 has been an eventful one for our young athletes, with many showcasing their talents at district and state-level competitions.Special coaching sessions in athletics were conducted during activity hours, focusing on sprint races, long-distance running, and other track events. Students were trained to compete at higher levels, and their dedication brought remarkable achievements.Our athletics program continues to inspire students to push their limits, embrace sportsmanship, and strive for excellence in every race they run." ,image:"sports1.jpg",
    title2:"Roller Skating",
    description2:"Our school offers specialized coaching sessions in roller skating during designated activity hours, providing students with an exciting opportunity to develop their skills in this dynamic sport. With an overwhelming response from students, the program continues to grow, fostering both enthusiasm and competitive spirit. Our skaters have achieved remarkable success in various competitions, showcasing their dedication and talent. As we strive for excellence, we are continuously working to enhance training facilities, introduce advanced coaching techniques, and expand participation, ensuring that every aspiring skater reaches their full potential.",image2:"sport3.jpg",
    title3:"Kabaddi",
    description3:"Our school actively promotes Kabaddi as part of its sports curriculum, encouraging students to develop teamwork, agility, and strategic thinking. With dedicated coaching sessions and well-structured training programs, students receive professional guidance to enhance their skills and performance. The sport has gained immense popularity among students, leading to impressive participation in inter-school and district-level competitions. As we continue to strengthen our Kabaddi program, we aim to provide better facilities and advanced training techniques to help our players excel and achieve greater success.Through regular practice and participation in intra-school and inter-school tournaments, students not only improve their physical fitness but also build resilience, leadership qualities, and quick decision-making skills. ",image3:"sport6.jpg",
    title4:"Volleyball",
    description4:"Our school provides Volleyball training as part of its comprehensive sports program, encouraging students to develop their teamwork, coordination, and athletic abilities. With dedicated coaching sessions led by experienced trainers, students receive structured guidance to enhance their skills in serving, passing, spiking, and defense strategies.Regular practice sessions and participation in inter-school tournaments help students refine their techniques and build confidence on the court. The sport has gained significant popularity among students, fostering a sense of sportsmanship, discipline, and perseverance.As part of our commitment to promoting Volleyball, we continue to improve training facilities, introduce advanced coaching methodologies, and provide competitive exposure to help students excel.  "
    ,image4:"volley.jpg",
    title5:"Yoga",
    description5:"Our school emphasizes the importance of Yoga as a vital practice for students' physical and mental well-being. Special yoga sessions are conducted during designated activity hours, guided by trained instructors who focus on enhancing flexibility, concentration, and overall health. The program has received positive feedback from students, helping them manage stress, improve posture, and boost mindfulness. As part of our continuous effort to promote holistic development, we aim to expand the yoga curriculum by introducing advanced techniques and increasing student participation. By integrating yoga into the daily routine, we strive to instill lifelong wellness habits, ensuring students achieve a balanced and healthy lifestyle.  "
    ,image5:"yoga.jpg",
    title6:"Karate",
    description6:"Our school offers Karate training as part of its extracurricular activities, focusing on discipline, self-defense, and physical fitness. Expert instructors conduct structured sessions during designated activity hours, ensuring students develop strength, agility, and confidence.The program has been well-received, with students actively participating and showing remarkable progress in their skills. We aim to further enhance the training by introducing advanced techniques and opportunities for students to compete at higher levels.Through Karate, we empower students with self-discipline, resilience, and the ability to defend themselves while promoting overall well-being. "
    ,image6:"karatta.jpg",

},
ncc: { heading:"NCC",title: "NCC", description: "Join the National Cadet Corps to develop leadership and patriotism." },
    nss: {heading:"NSS", title: "NSS", description: "Be a part of the National Service Scheme and contribute to social causes." },
    drawing: { heading:"Drawing Class",title: "Drawing Class", description: "Schools provide drawing classes as a creative platform for students to express their imagination and develop artistic skills. These classes introduce fundamental techniques such as sketching, shading, and color theory, allowing students to explore different art forms. Through structured lessons and hands-on practice, students enhance their creativity, improve hand-eye coordination, and gain confidence in their artistic abilities. Drawing classes also encourage self-expression and critical thinking, helping students develop a deeper appreciation for art. Whether as a hobby or a potential career path, these classes play a crucial role in nurturing young talent and fostering a lifelong love for creativity.",image:"drawing.jpg" }
};

const Curriculum = () => {
    const { id } = useParams(); // Get id from URL
    const [content, setContent] = useState(clubContent["clubs"]); // Default Content
    useEffect(() => {
        if (clubContent[id]) {
            setContent(clubContent[id]); // Update content when id changes
        } else {
            setContent(clubContent["clubs"]); // Fallback to "clubs"
        }
    }, [id]);
    console.log(id);

    return (
        <Styles>
            <div className="main-wrapper instructor-details-page">
                <HeaderTwoDefault />
                <BreadcrumbBoxDefault title={content?.heading} />

                <section className="instructor-details-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${content?.image}`} alt="" className="img-fluid" />
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="instructor-content">
                                    <div className="instructor-course-title">
                                        <h5>{content?.title}</h5>
                                    </div>
                                    <p>{content?.description}</p>
                                </div>
                            </Col>
                            {content.title2 && (
                                <>
                         
                            <Col md="8">
                                <div className="instructor-content">

                                    <div className="instructor-course-title">
                                        <h5>{content?.title2}</h5>
                                    </div>
                                    <p>{content?.description2}</p>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${content.image2}`} alt="" className="img-fluid" />
                                </div>
                            </Col>
                                </>
                            )}

                            {content.title3 && (
                                <>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${content.image3}`} alt="" className="img-fluid" />
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="instructor-content">
                                    <div className="instructor-course-title">
                                        <h5>{content.title3}</h5>
                                    </div>
                                    <p>{content.description3}</p>
                                </div>
                            </Col>
                            </>
                            )}
                            {content.title4 && (<>
                            <Col md="8">
                                <div className="instructor-content">
                                    <div className="instructor-course-title">
                                        <h5>{content.title4}</h5>
                                    </div>
                                    <p>{content.description4}</p>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${content.image4}`} alt="" className="img-fluid" />
                                </div>
                            </Col>
                            </>)}
                            {content.title5 &&(<>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${content.image5}`} alt="" className="img-fluid" />
                                </div>
                            </Col>
                            <Col md="8">
                                <div className="instructor-content">
                                    <div className="instructor-course-title">
                                        <h5>{content.title5}</h5>
                                    </div>
                                    <p>{content.description5}</p>
                                </div>
                            </Col>
                            </>)}
                            {content.title6 && (<>
                            <Col md="8">
                                <div className="instructor-content">
                                    <div className="instructor-course-title">
                                        <h5>{content.title6}</h5>
                                    </div>
                                    <p>{content.description6}</p>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="instructor-img">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${content.image6}`} alt="" className="img-fluid" />
                                </div>
                            </Col>
                            </>)}
                        </Row>
                    </Container>
                </section>

                <FooterTwoDefault />
            </div>
        </Styles>
    );
};

export default Curriculum;
