import React from 'react';
import { Styles } from './styles/contact.js';

const GoogleMap = () => {
    return (
        <Styles>
            {/* Google Map */}
            <div className="google-map-area" style={{ width: '100%', height: '600' }}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3933.9066463284757!2d77.173762!3d9.6033066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0655bac0000003%3A0x72e3c9b9d987cf6d!2sAmalambika%20Convent%20English%20School!5e0!3m2!1sen!2sin!4v1739323999219!5m2!1sen!2sin"
                    width="100%"
                    title="Embedded Example Website"
                    height="600"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </Styles>
    );
};

export default GoogleMap;
