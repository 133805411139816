import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import FacilityLevel from './FacilityLevel';
import InfrastructureImages from './InfrastructureImage';
import InfrastructureCategorys from './InfrastructureCategory';

class InfrastructureSidebar extends Component {
    render() {
        return (
            <div className="course-sidebar">
                <Row>
                    {/* <Col md="12">
                        <CourseSearch />
                    </Col> */}
                    <Col md="12">
                        <InfrastructureCategorys />
                    </Col>
                    <Col md="12">
                        <FacilityLevel />
                    </Col>
                    <Col md="12">
                        <InfrastructureImages />
                    </Col>
                    {/* <Col md="12">
                        <CourseTag />
                    </Col> */}
                </Row>
            </div>
        )
    }
}

export default InfrastructureSidebar